.FarmProducts {
  width: 100vw;
  z-index: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
}

.FarmProductsInner {
  width: 100vw;
  height: 100vh;
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 5;
  pointer-events: none;
}

.ProductsPlInner {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
}

.FarmProductsHeader {
  width: 100vw;
  background-color: #fff;
  padding-top: 0px;
  padding-bottom: 0px;
  z-index: 10;
  position: absolute;
  bottom: 0;
}
.FarmProductsHeaderText {
  color: #44a99a;
  font-size: 260%;
  text-align: left;
  margin: 0;
  margin-left: 10px;
  font-weight: 900;
  font-family: "Press Start 2P", cursive;
  margin-bottom: -5px;
  z-index: 5;
  position: unset;
}

.SkipDownButton {
  position: absolute !important;
  bottom: 5%;
  right: 3%;
  z-index: 5;
  color: rgba(0, 155, 140, 0.85) !important;
}

.ProductsItemOuter {
  width: 60vw;
  height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: left;
  z-index: 10;
  will-change: auto;
  position: absolute;
  top: 18vh;
}

.ProductsItemOuterAlt {
  width: 60vw;
  height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: left;
  z-index: 10;
  will-change: auto;
  position: absolute;
}

.ProductsItemOuterInner {
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: left;
  transform: translate3d(0, 0, 0);
}

.ProductsItemTitle {
}

.ProductsItemImg {
}

.ProductsItemInner {
  height: 95%;
  width: 94%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.207);
}

.ProductsCardOuter {
  height: 40%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: left;
}

.ProductsImg {
  width: 100%;
  height: auto;
}

.ProductsCardInner {
  width: 100%;
  height: 55%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  text-align: left;
}

.ProductsCardInnerFacts {
  width: 65%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  text-align: left;
}

.ProductsCardChartOuter {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 35%;
  padding: 3px;
  text-align: center;
  height: 85%;
}

.ProductsCardChartOuter canvas {
  height: 50% !important;
  width: auto !important;
  overflow: visible;
}

.ProductsCardChartTitle {
  font-size: 65%;
  font-weight: 500;
  margin: 6px;
}

.ProductsCardChartTiny {
  font-size: 105%;
  font-weight: 300;

  text-align: center;
}

.RatingElement {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 80px;
}

.RatingElementInner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 64px;
  width: 50px;
}

.RatingItem {
  height: 6px;
  width: 30px;
  border: 1px solid;
  border-color: rgba(0, 155, 140, 0.9);
  border-radius: 3px;
}

.RatingItemColored {
  height: 6px;
  width: 30px;
  border: 1px solid;
  border-color: rgba(0, 155, 140, 0.9);
  border-radius: 3px;
  background-color: rgba(0, 155, 140, 0.9);
}

.RatingTitle {
  width: 50px;
  font-size: 65%;
  text-align: center;
}

@media only screen and (max-width: 900px) {
  .ProductsItemOuter {
    width: 70vw;
    height: 80vh;
  }
}
@media only screen and (max-width: 700px) {
  .ProductsItemOuter {
    width: 80vw;
  }
}

@media only screen and (max-width: 600px) {
  .FarmProductsHeaderText {
    font-size: 200%;
    margin-bottom: -4px;
  }
}

@media only screen and (max-width: 500px) {
  .ProductsItemOuter {
    width: 100vw;
    height: 96vh;
  }

  .FarmProductsHeaderText {
    font-size: 160%;
    margin-bottom: -3px;
  }
}

@media only screen and (max-width: 400px) {
  .FarmProductsHeaderText {
    font-size: 130%;
    margin-bottom: -3px;
  }
}

@media only screen and (max-width: 350px) {
  .ProductsItemOuter {
    width: 100vw;
    height: 65vh;
  }
  .FarmProductsHeaderText {
    font-size: 110%;
    margin-bottom: -2px;
  }
}

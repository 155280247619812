.VLogo {
  width: 100%;
  height: auto;
  z-index: 10;
  overflow: visible;
}
.ErtLogo {
  width: 100%;
  height: auto;
  z-index: 8;
  margin-bottom: 1px;
  overflow: visible;
}

.FarmLogo {
  width: 80%;
  height: auto;
  z-index: 6;

  margin-bottom: -3%;
  overflow: visible;
}

.MainLogo {
  max-height: 360px;
  z-index: 10;
  overflow: hidden;
}

.MainLogoText {
  overflow: visible;
  transform-origin: center center;
}

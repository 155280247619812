.MainStart {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.MainStartInner {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: relative;
  overflow: hidden;
  top: 0;
  left: 0;
}

.MainStartInnerTop {
  width: 100%;
  height: 35%;
  position: relative;
  top: 0;
  left: 0;
}

.MainStartInnerBottom {
  width: 100%;
  height: 35%;
  position: relative;
  bottom: 0;
  left: 0;
}

.MainStartButtons {
  width: 100%;
  height: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.MainStartButtonsInner {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.MainStartLogo {
  width: 60%;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
}
.MainStartLogoBack {
  width: 40%;
  height: 220px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: calc(50% - 110px);
  left: 30%;
}

.MainStartImageGap {
  width: 100%;
  min-height: 110px;
  height: 110px;
}

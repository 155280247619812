.FarmStart {
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: white;
  z-index: 10;
}

.FarmStartOuter {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
}
.FarmStartOverlay {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  overflow: hidden;
  z-index: 10;
}
.FarmStartOverlayLogo {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  background-color: rgba(70, 170, 155, 0.99);
  overflow: hidden;
  top: 0;
}
.FarmStartInnerTop {
  width: 100%;

  overflow: hidden;
}

.FarmStartInnerTopLast {
  width: 100%;
  height: calc((100% / 2) - 60px);
  position: relative;
  left: 0;
}

.FarmStartInnerBottom {
  width: 100%;
  height: calc((100% / 2) - 60px);
  position: relative;
  top: 120px;
  left: 0;
}

.FarmStartLogo {
  width: 15%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
}
.FarmStartLogoBack {
  width: 25%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;

  margin-top: 12.5%;
}

.FarmStartLogoOuter {
  width: 100%;
  height: 20%;
  position: relative;
  z-index: 6;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.FarmStartBottom {
  width: 100%;
  max-width: 1600px;
  height: 40%;
  position: relative;
  z-index: 6;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.FarmStartBottomInner {
  width: 80%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
}

.FarmStartBottomText {
  color: rgba(70, 170, 155, 0.99);
  font-family: "Poppins";
  font-weight: 700;
  font-size: 300%;
  margin-bottom: 0px;
}

.FarmStartBottomSubText {
  color: rgba(30, 30, 30, 0.99);
  font-family: "Poppins";
  font-weight: 500;
  font-size: 100%;
  margin-bottom: 2%;
  text-align: left;
  margin-left: 1%;
}

.FarmStartBackdrop {
  min-width: 350px;
  max-width: 800px;
  width: 70%;
  height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  overflow: hidden;
  z-index: 4;
  background-color: rgba(70, 170, 155, 0.99);
  border-radius: 6px 6px 0 0;
  box-shadow: 0px -1px 2px rgba(50, 50, 50, 0.3);
}

.StartNewInnerTextOuter {
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  position: absolute;
  bottom: 10%;
  left: 5vw;
  width: 50vw;
  z-index: 5;
}
.StartNewInnerTextDiv {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  overflow: hidden;
}

.StartNewInnerTitle {
  color: #414141;
  font-family: "Source Code Pro", monospace;
  font-size: 300%;
  font-weight: 700;
  text-align: left;
  opacity: 0.9;
  margin: 0;
}

.StartNewInnerText {
  color: #515151;
  font-family: "Source Code Pro", monospace;
  font-size: 150%;
  font-weight: 700;
  text-align: left;
  margin: 0;
}

@media only screen and (max-width: 700px) {
  .StartNewInnerTextOuter {
    bottom: 7%;
    left: 4vw;
    width: 80vw;
  }
}

@media only screen and (max-width: 600px) {
  .StartNewInnerTitle {
    font-size: 250%;
  }

  .StartNewInnerText {
    font-size: 120%;
  }
}

@media only screen and (max-width: 500px) {
  .StartNewInnerTitle {
    font-size: 200%;
  }

  .StartNewInnerText {
    font-size: 110%;
  }
}

.FarmProductsPage {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-height: 100vh;
}

.FarmProductsPageHeader {
  width: 100%;
  background-color: #44a99a;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
}

.FarmProductsPageHeaderNav {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  padding-top: 10px;
  margin-right: 10px;
}

.FarmProductsPageHeaderDiv {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.FarmProductsPageHeaderLogo {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: white;
  padding-left: 20px;
  padding-right: 20px;
}

.FarmProductsPageLogo {
  height: 60px;
}

.FarmProductsPageHeaderTitle {
  font-size: 180%;
  text-align: left;
  margin: 0;
  font-weight: 700;
  font-family: "Source Code Pro", monospace;
  width: 100%;
  margin-top: 30px;
  margin-bottom: 0px;
  color: white;
}
.FarmProductsPageHeaderText {
  font-size: 100%;
  text-align: left;
  margin: 0;
  font-weight: 500;
  font-family: "Source Code Pro", monospace;
  width: 100%;
  margin-top: 0px;
  margin-bottom: 5px;
  color: white;
  font-style: italic;
}

.FarmProductPageLogo {
  width: 90%;
}

.FarmProductsPageTop {
  width: 100vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
  margin-top: 0px;
}
.FarmProductsPageSlider {
  width: 70%;
  height: auto;
  max-width: 700px;
}

.FarmProductsPageInfo {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

.FarmProductsPageRating {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 0px;
  margin-top: 16px;
}

.FarmProductsPageRatingItem {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 30%;
}

.TraceFactsTinyTitle {
  font-size: 80%;
  font-weight: 500;
  margin: 6px;
}

.TraceFactsTinyText {
  font-size: 110%;
  font-weight: 300;

  text-align: center;
}

.FarmProductsPageInfoTop {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #44a99a;
}
.FarmProductsPageInfoDivider {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  background-color: #44a99a;
}
.TraceFactsOuterPower {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 0px;
  background-color: #44a99a;
}

.TraceFactsOuterPowerTitle {
  font-size: 240%;
  text-align: left;
  margin: 0;
  font-weight: 700;
  font-family: "Source Code Pro", monospace;
  width: 95%;
  color: white;
  background-color: #44a99a;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding-top: 10px;
  padding-bottom: 0px;
  padding-left: 5%;
}

.TraceFactsOuterPowerInnerText {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 45%;
  background-color: #44a99a;
  color: white;
  padding-top: 20px;
  margin-bottom: 40px;
}

.TraceFactsOuterPowerInner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 45%;
  overflow: visible !important;
  background-color: #44a99a;
  color: white;
  padding-top: 30px;
  padding-bottom: 20px;
  margin-bottom: 40px;
}

.TraceFactsOuterPowerInner canvas {
  height: auto !important;
  width: 55% !important;
  overflow: visible !important;
}

.TraceFactsImg {
  width: 60%;
  height: auto;
}

.FarmProductsPageAbout {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin-top: 20px;
}
.FarmProductsPageAboutSection {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  width: 100%;
}

.FarmProductsPageAboutSectionAlt {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  width: 50%;
}
.FarmProductsPageAboutTagsAlt {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 92%;

  padding-top: 10px;
  padding-bottom: 30px;
}
.FarmProductsPageAboutTitle {
  font-size: 240%;
  text-align: center;
  margin: 0;
  font-weight: 700;
  font-family: "Source Code Pro", monospace;
  width: 12%;
  color: white;
  background-color: #44a99a;
  writing-mode: vertical-rl;
  text-orientation: upright;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
  padding-bottom: 10px;
}
.FarmProductsPageAboutText {
  font-size: 110%;
  text-align: left;
  margin: 0;
  font-weight: 400;
  font-family: "Poppins";
  width: 88%;
  color: #323741;
  margin-left: 1%;
  margin-right: 2%;

  padding-top: 5px;
  padding-bottom: 5px;
}

.FarmProductsPageAboutTags {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-evenly;
  flex-wrap: wrap;
  width: 88%;

  padding-top: 30px;
  padding-bottom: 30px;
}

.FarmProductsPageAboutTag {
  font-size: 95%;
  padding: 14px;
  background-color: #44a99a;
  color: white;
  margin-top: 5px;
  margin-bottom: 5px;
  width: 40%;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 25px;
  padding-top: 25px;
}

.FarmProductsPageFacts {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  width: 89%;
  padding-top: 10px;
  padding-bottom: 50px;
}

.FarmProductsPageFactsInner {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 48%;
}

.FarmProductsPageFactsText {
  font-size: 90%;
  text-align: left;
  margin: 0;
  font-weight: 400;
  font-family: "Poppins";
  width: 90%;
  color: black;
}

.FarmProductsPageFactsTitle {
  font-size: 95%;
  text-align: left;
  margin: 0;
  font-weight: 700;
  font-family: "Poppins";
  width: 90%;
}

.FarmProductsPageTrace {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  padding-bottom: 0px;
  padding-top: 20px;
}
.FarmProductsPageTraceHeader {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-around;
  background-color: white;
}
.FarmProductsPagePowerHeader {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-around;
  background-color: #44a99a;
}

.FarmProductsPageTraceHeaderTitle {
  font-size: 240%;
  text-align: left;
  margin: 0;
  font-weight: 700;
  font-family: "Source Code Pro", monospace;
  width: 95%;
  color: #44a99a;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding-top: 10px;
  padding-bottom: 0px;
  padding-left: 5%;
}

.FarmProductsPagePowerHeaderTitle {
  font-size: 240%;
  text-align: left;
  margin: 0;
  font-weight: 700;
  font-family: "Source Code Pro", monospace;
  width: 95%;
  color: white;
  background-color: #44a99a;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding-top: 10px;
  padding-bottom: 0px;
  padding-left: 5%;
}

.FarmProductsPageTraceInner {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: white;
}

.FarmProductsPageTraceResult {
  width: 100%;
}

.carousel .control-dots .dot {
  background-color: #44a99a;
}
.carousel.carousel-slider .control-arrow:hover {
  background-color: #44a99a08;
}

.carousel .control-next.control-arrow:before {
  border-left: 8px solid #44a99a;
}
.carousel .control-prev.control-arrow:before {
  border-right: 8px solid #44a99a;
}

.carousel .slide img {
  vertical-align: unset !important;
}

.carousel .slider-wrapper.axis-horizontal .slider .slide {
  justify-content: center;
  display: flex;
}

@media only screen and (max-width: 800px) {
  .FarmProductsPageTop {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  .FarmProductsPageSlider {
    width: 100%;
    align-self: center;
  }
  .FarmProductsPageInfo {
    width: 100%;
    margin-top: 0px;
  }
  .FarmProductsPageAboutText {
    font-size: 105%;
  }
  .FarmProductsPageAboutTag {
    font-size: 95%;
    padding: 10px;
    padding-bottom: 20px;
    padding-top: 20px;
  }
}

@media only screen and (max-width: 600px) {
  .FarmProductsPageSlider {
    width: 100%;
  }

  .FarmProductsPageInfo {
    width: 100%;
  }

  .TraceFactsOuterPowerInnerText {
    width: 100%;
  }

  .TraceFactsOuterPowerInner {
    width: 60%;
  }
  .FarmProductsPageAboutText {
    font-size: 100%;
  }

  .FarmProductsPageAboutTag {
    font-size: 90%;
  }
}

@media only screen and (max-width: 400px) {
  .FarmProductsPageSlider {
    width: 100%;
  }

  .FarmProductsPageInfo {
    width: 100%;
  }
  .TraceFactsOuterPowerInner {
    width: 80%;
  }

  .FarmProductsPageAboutText {
    font-size: 95%;
  }

  .FarmProductsPageAboutTag {
    font-size: 85%;
    font-weight: 500;
    padding: 6px;
    padding-bottom: 16px;
    padding-top: 16px;
  }
}

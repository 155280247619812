.FarmPartners {
  width: 100vw;
  z-index: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  background-color: #44a99a;
  min-height: 100vh;
}

.FarmPartnersInner {
  width: 100vw;
  height: 100vh;
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 5;
  pointer-events: none;
  background-color: transparent;
}

.FarmPartnersHeader {
  width: 100vw;
  background-color: #44a99a;
  padding-top: 0px;
  padding-bottom: 0px;
  z-index: 10;
  position: absolute;
  bottom: 0;
  margin-bottom: -5px;
}

.FarmPartnersHeaderText {
  font-size: 240%;
  text-align: left;
  margin: 0;
  margin-left: 10px;
  font-weight: 900;
  font-family: "Press Start 2P", cursive;
  margin-bottom: 0px;
  z-index: 5;
  position: unset;
  color: #fff;
}

.FarmPartnersListOuter {
  width: 100%;
  max-width: 1400px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 30px;
  padding-top: 120px;
  z-index: 4;
}

.FarmPartnersTopText {
  font-size: 105%;
  text-align: left;
  margin: 0;
  font-weight: 500;
  font-family: "Poppins";
  width: 80%;
  margin-top: 10px;
  margin-bottom: 19px;
  color: white;
}

.FarmPartnersListInner {
  width: 82%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 0px;
  z-index: 4;
  padding-top: 50px;
  padding-bottom: 120px;
}

.FarmPartnersListInnerDiv {
  width: 22%;
  aspect-ratio: 1/1.3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
  background-color: white;
  margin-left: 1.5%;
  margin-right: 1.5%;
}

.FarmPartnersListInnerDivTop {
  width: 90%;
  height: 55%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
}

.FarmPartnersListInnerDivBottom {
  width: 100%;
  height: 45%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: white;
}

.FarmPartnersListInnerDivBottomTitle {
  font-size: 95%;
  text-align: left;
  margin: 0;
  font-weight: 600;
  font-family: "Poppins";
  width: 90%;
  color: #44a99a;
  margin-left: 5%;
}
.FarmPartnersListInnerDivBottomText {
  font-size: 85%;
  text-align: left;
  margin: 0;
  font-weight: 400;
  font-family: "Poppins";
  width: 90%;
  color: black;
  margin-left: 5%;
  font-style: italic;
}

.FarmPartnersListInnerDivText {
  font-size: 180%;
  text-align: center;
  margin: 0;
  font-weight: 900;
  font-family: "Source Code Pro", monospace;
  width: 22%;
  aspect-ratio: 1/1.3;
  color: white;
  background-color: #44a99a;
  writing-mode: vertical-rl;
  text-orientation: upright;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;
  margin-left: 1.5%;
  margin-right: 1.5%;
}

@media only screen and (max-width: 1200px) {
  .FarmPartnersListInnerDivText {
    font-size: 140%;
  }
}

@media only screen and (max-width: 1000px) {
  .FarmPartnersListInnerDivText {
    width: 30%;
    margin-left: 1.666%;
    margin-right: 1.666%;
    font-size: 150%;
  }

  .FarmPartnersListInnerDiv {
    width: 30%;
    margin-left: 1.666%;
    margin-right: 1.666%;
  }
}

@media only screen and (max-width: 700px) {
  .FarmPartnersListInnerDivText {
    width: 45%;
    margin-left: 2.5%;
    margin-right: 2.5%;
    font-size: 160%;
  }

  .FarmPartnersListInnerDiv {
    width: 45%;
    margin-left: 2.5%;
    margin-right: 2.5%;
  }
  .FarmPartnersListInnerDivBottomTitle {
    font-size: 110%;
  }

  .FarmPartnersListInnerDivBottomText {
    font-size: 100%;
  }
}

@media only screen and (max-width: 600px) {
  .FarmPartnersListInnerDivText {
    font-size: 150%;
  }

  .FarmPartnersListInner {
    width: 90%;
  }
  .FarmPartnersListInnerDivBottomTitle {
    font-size: 95%;
  }

  .FarmPartnersListInnerDivBottomText {
    font-size: 85%;
  }

  .FarmPartnersHeaderText {
    font-size: 200%;
  }
  .FarmPartnersHeader {
    margin-bottom: -4px;
  }
}

@media only screen and (max-width: 500px) {
  .FarmPartnersListInnerDivText {
    font-size: 130%;
  }

  .FarmPartnersListInner {
    width: 96%;
  }
  .FarmPartnersListInnerDivBottomTitle {
    font-size: 85%;
  }

  .FarmPartnersListInnerDivBottomText {
    font-size: 75%;
  }
  .FarmPartnersHeaderText {
    font-size: 160%;
  }
  .FarmPartnersHeader {
    margin-bottom: -3px;
  }
}

@media only screen and (max-width: 400px) {
  .FarmPartnersListInnerDivText {
    aspect-ratio: 1/1.6;
    font-size: 120%;
  }

  .FarmPartnersListInnerDiv {
    aspect-ratio: 1/1.6;
  }
  .FarmPartnersHeaderText {
    font-size: 130%;
  }

  .FarmPartnersHeader {
    margin-bottom: -2px;
  }
}

@media only screen and (max-width: 350px) {
  .FarmPartnersHeaderText {
    font-size: 110%;
  }

  .FarmPartnersHeader {
    margin-bottom: -2px;
  }
}

.LabHow {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  width: 100vw;
  background-color: #fff;
  overflow-x: auto;
  z-index: 0;
}

.HowHeader {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 900px;
}

.HowTitle {
  padding: 0;
  font-size: 200%;
  font-weight: bold;
  color: #323741;
  width: 90%;
  font-family: "Source Code Pro", monospace;
}

.HowInnerStart {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
}

.HowMobileDiv {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 700px;
  opacity: 0;
}

.HowMobileDivInner {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
}

.LogoText {
  padding: 0;
  margin: 5px;
  color: #323741;
  text-align: center;
  font-size: 110%;
  font-weight: 500;
  width: 90%;
  font-family: "Poppins";
}

.HowMobileDivInnerList {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.HowMobileDivInnerPlx {
  opacity: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 100%;
}

.HowMobileDivInnerPlxInner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 30%;
}

.HowMobileDivInnerPlxTitle {
  margin: 5px;
  font-weight: bold;
  color: #226b5e;
  font-family: "Source Code Pro", monospace;
}

.HowMobileDivInnerPlxSubtitle2 {
  margin: 5px;
  color: #323741;
  width: 65%;
  text-align: left;
  font-family: "Poppins";
}

.HowInnerCloud {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  background-color: #323741;
  position: relative;
  z-index: 2;
}

.CloudParticles {
  position: absolute;
  top: 0;
  opacity: 0.2;
  z-index: 3;
}

.HowInnerCloudMargin {
  width: 100%;
  max-width: 900px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  z-index: 2;
}

.HowFirebase {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 96%;
  max-width: 700px;
  opacity: 0;
}

.HowGoogle {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 900px;
  opacity: 0;
  z-index: 4;
}

.HowCloudImage {
  z-index: 5;
  width: 103%;
  height: 100%;
  object-fit: cover;
}

.FirebaseLogo {
  width: 30%;
  margin-bottom: 20px;
}

.HowInnerConsumer {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  position: relative;
}

.HowInnerConsumerMargin {
  width: 100%;
  max-width: 900px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  top: 0;
  z-index: 1;
  opacity: 0;
}

.HowInnerCommercial {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  background-color: #323741;
  z-index: 2;
}

.HowInnerCommercialMargin {
  width: 100%;
  max-width: 900px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
}

.HowModularOne {
  width: 34%;
}

.ModularLogo {
  width: 80%;
}
.OrText {
  padding: 0;
  margin: 50px;
  font-size: 300%;
  font-weight: bold;
  color: #226b5e;
  opacity: 0.9;
}

.OrTextNoMargin {
  padding: 0;
  margin: 0;
  font-size: 250%;
  font-weight: bold;
  color: #226b5e;
  opacity: 0.9;
  text-align: left;
  width: 100%;
  font-family: "Source Code Pro", monospace;
  margin-top: 20px;
}

.FirebaseText {
  padding: 0;
  color: #e0e0e0;
  text-align: left;
  width: 100%;
  margin-top: 0px;
  font-size: 120%;
  font-weight: 500;
  font-family: "Poppins";
}

.HowConsumerText {
  padding: 0;
  color: #323741;
  text-align: left;
  width: 100%;
  margin-top: 0px;
  font-size: 120%;
  font-weight: 500;
  font-family: "Poppins";
}

.HowConsumerTextDiv {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 0;
  text-align: center;
  max-width: 700px;
  margin-right: 10px;
  margin-left: 10px;
}

.HowConsumerLogos {
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  max-width: 600px;
  opacity: 0.9;
}
.HowCommercialLogos {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 700px;
}

.HowCommercialInner {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  position: relative;
  width: 100%;
}

.HowCommercialDiv {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 0;
  background-color: #44a99a;
  opacity: 0;
  width: 100%;
  max-width: 700px;
}

.CommercialLogo {
  width: 100%;
  max-width: 700px;
  object-fit: cover;
}

.HowCommercialText {
  font-weight: bold;
  color: #fff;
  margin-top: -10px;
  font-size: 130%;
  font-family: "Source Code Pro", monospace;
}
.HowMobileDivInnerPlxSubtitle {
  padding: 0;
  margin: 5px;
  color: #323741;
  text-align: center;
  font-size: 110%;
  font-weight: 500;
  width: 90%;
  font-family: "Poppins";
}

#tsparticles canvas {
  z-index: -1 !important;
  position: static;
}
@media only screen and (max-width: 700px) {
  .OrTextNoMargin {
    font-size: 220%;
  }

  .FirebaseText {
    font-size: 110%;
  }
  .HowConsumerText {
    font-size: 110%;
  }

  .HowCommercialText {
    font-size: 120%;
  }

  .HowMobileDivInnerPlxSubtitle {
    font-size: 105%;
  }
}

@media only screen and (max-width: 500px) {
  .HowTitle {
    font-size: 170%;
  }
  .LogoText {
    font-size: 110%;
  }

  .HowMobileDivInnerPlxTitle {
    font-size: 100%;
  }

  .HowMobileDivInnerPlxSubtitle2 {
    font-size: 90%;
  }
  .OrTextNoMargin {
    font-size: 190%;
  }

  .FirebaseText {
    font-size: 105%;
  }
  .HowConsumerText {
    font-size: 105%;
  }
  .HowCommercialText {
    font-size: 110%;
  }

  .HowMobileDivInnerPlxSubtitle {
    font-size: 100%;
  }
}

@media only screen and (max-width: 400px) {
  .HowTitle {
    font-size: 160%;
  }

  .LogoText {
    font-size: 110%;
  }

  .HowMobileDivInnerPlxTitle {
    font-size: 90%;
  }

  .HowMobileDivInnerPlxSubtitle2 {
    font-size: 85%;
  }
  .OrTextNoMargin {
    font-size: 170%;
  }

  .FirebaseText {
    font-size: 100%;
  }
  .HowConsumerText {
    font-size: 100%;
  }

  .HowFirebase {
    width: 92%;
  }
  .HowInnerConsumerMargin {
    width: 98%;
  }

  .HowMobileDivInnerPlxSubtitle {
    font-size: 95%;
  }
}

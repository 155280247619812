.ContactOuter {
  width: 100vw;
  z-index: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  background-color: #44a99a;
  min-height: 100vh;
}

.ContactOverlay {
  width: 100vw;
  height: 100vh;
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 5;
  pointer-events: none;
  background-color: transparent;
}

.ContactHeader {
  width: 100vw;
  background-color: #44a99a;
  padding-top: 0px;
  padding-bottom: 0px;
  z-index: 10;
  position: absolute;
  bottom: 0;
  margin-bottom: -5px;
}

.ContactHeaderText {
  color: #fff;
  font-size: 260%;
  text-align: left;
  margin: 0;
  margin-left: 10px;
  font-weight: 900;
  font-family: "Press Start 2P", cursive;
  margin-bottom: 0px;
  z-index: 5;
  position: unset;
}

.ContactInner {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 30px;
  z-index: 4;
}

.ContactInnerTop {
  width: 100%;
  min-height: 200px;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ContactInnerLinksDiv {
  width: 100%;
  max-width: 1400px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 80px;
  margin-top: 80px;
}

.ContactInnerLink {
  width: 20%;
}
.ContactInnerDivider {
  width: 100%;
  background-color: #44a99a;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.ContactInnerDividerImg {
  width: 25%;
  height: auto;
}

.ContactInnerMain {
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
  padding-bottom: 80px;
  padding-top: 80px;
  margin-top: 20px;
  background-color: white;
  padding-left: 5%;
  padding-right: 5%;
  box-shadow: inset 0 3px 3px -3px #3f3f3f;
}
.ContactInnerMainDiv {
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 600px;
}
.ContactInnerMapsDiv {
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 600px;
}

.ContactInnerMainAddress {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 15px;
  margin-top: 15px;
}

.ContactInnerMainAddressText {
  font-size: 100%;
  text-align: left;
  margin: 0;
  font-weight: 600;
  font-family: "Poppins";
  width: 80%;
  color: #4b4b4b;
}

.ContactInnerMainAddressNumber {
  font-size: 100%;
  text-align: left;
  margin: 0;
  font-weight: 600;
  font-family: "Poppins";
  width: 80%;
  color: #4b4b4b;
}

.MapsElement {
  width: 100%;
  border: none;
  height: 100%;
}

.StatusOuter {
  width: 100%;
  max-width: 1400px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: stretch;
  flex-wrap: wrap;
  margin-top: 80px;
  margin-bottom: 80px;
}

.StatusItem {
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.StatusOuterTitle {
  width: 100%;
  font-size: 160%;
  text-align: center;
  font-weight: 700;
  font-family: "Source Code Pro", monospace;
  color: #44a99a;
  margin-bottom: 20px;
}

.StatusInnerTitle {
  width: 97%;
  text-align: left;
  padding-left: 3%;
  font-size: 100%;
  text-align: left;
  margin: 0;
  font-weight: 600;
  font-family: "Poppins";
  color: white;
  background-color: #44a99a;
}

.StatusInnerImgOuter {
  width: 100%;
  position: relative;
  aspect-ratio: 1366/768;
}

.StatusInnerImg {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.StatusItemBottom {
  width: calc(100% - 10px);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  align-content: flex-start;
  flex-wrap: wrap;
  border: 5px solid #44a99a;
  flex: 1;
  padding-top: 10px;
  padding-bottom: 10px;
}

.StatusItemBottomItem {
  width: 33%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  color: #44a99a;
  margin-top: 10px;
  margin-bottom: 10px;
}

.StatusItemBottomItemWide {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  color: #44a99a;
  margin-top: 10px;
  margin-bottom: 10px;
}

.StatusItemBottomItemWideBottom {
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 0;
  margin-top: 3px;
}

.StatusItemPlantLogo {
  width: 24px;
  margin: 3px;
}

.StatusItemBottomTitle {
  width: 90%;
  font-size: 75%;
  text-align: center;
  font-weight: 700;
  font-family: "Source Code Pro", monospace;
  color: #4b4b4b;
  margin: 0;
}

.StatusItemBottomItemWide .StatusItemBottomTitle {
  font-size: 85%;
}

.StatusItemBottomText {
  width: 90%;
  font-size: 110%;
  text-align: center;
  font-weight: 600;
  font-family: "Poppins";
  margin: 0;
}

.StatusItemBottomTextSmall {
  width: 96%;
  font-size: 85%;
  text-align: center;
  font-weight: 600;
  font-family: "Poppins";
  margin: 0;
  margin-top: 4px;
}

@media only screen and (max-width: 800px) {
  .StatusItemBottomTitle {
    font-size: 70%;
  }

  .StatusItemBottomText {
    font-size: 100%;
  }

  .StatusItemBottomTextSmall {
    font-size: 75%;
    margin-top: 5px;
  }
  .StatusItem {
    width: 70%;
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 700px) {
  .ContactInnerLink {
    width: 40%;
    margin-bottom: 20px;
    margin-top: 20px;
  }
}

@media only screen and (max-width: 600px) {
  .ContactInnerMain {
    flex-direction: column;
    align-items: center;
  }

  .ContactInnerMainDiv {
    width: 80%;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
  }

  .ContactInnerMapsDiv {
    width: 80%;
  }
  .ContactInnerMainAddress {
    width: 45%;
  }
  .ContactInnerMainAddressNumber {
    text-align: right;
    width: 100%;
    font-size: 90%;
  }
  .ContactInnerMainAddressText {
    width: 100%;
    font-size: 90%;
  }

  .ContactHeaderText {
    font-size: 200%;
    margin-bottom: 0px;
  }
  .ContactInnerDividerImg {
    width: 46%;
    height: auto;
    margin-bottom: 10px;
  }
  .ContactInnerDivider {
    justify-content: space-evenly;
  }
}

@media only screen and (max-width: 500px) {
  .ContactInnerMainDiv {
    width: 90%;
  }

  .ContactInnerMapsDiv {
    width: 90%;
  }
  .ContactHeaderText {
    font-size: 160%;
    margin-bottom: 1px;
  }
  .StatusItemBottomItem {
    width: 50%;
  }
  .StatusItem {
    width: 80%;
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 400px) {
  .ContactInnerMainAddressNumber {
    font-size: 80%;
  }

  .ContactInnerMainAddressText {
    font-size: 80%;
  }

  .ContactHeaderText {
    font-size: 130%;
    margin-bottom: 2px;
  }
}

@media only screen and (max-width: 350px) {
  .ContactHeaderText {
    font-size: 110%;
    margin-bottom: 3px;
  }
}

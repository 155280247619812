.Loading {
  width: 300px;
  height: 100px;
}

.LanguagePicker {
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  top: 0;
  right: 0;
  z-index: 20;
}

.LanguageText {
  color: #44a99a;
  font-size: 80%;
  margin-right: 5px;
  opacity: 0.9;
}

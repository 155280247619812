.FarmProductsListOuter {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-bottom: 0px;
  z-index: 4;
}

.FarmProductsListTop {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 120px;
  background-color: #44a99a;

  box-shadow: 0 2px 1px #3f3f3f48;
}

.FarmProductsListBottom {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 90px;
  margin-top: 120px;
}

.FarmProductsListItem {
  width: 27%;
  cursor: pointer;
  z-index: 0;
}
.FarmProductsListItemInner {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
  cursor: pointer;
  z-index: 0;
}

.FarmProductsListImg {
  width: 100%;
}

.FarmProductsListLogo {
  width: 30px;
}
.FarmProductsListItemBottom {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.FarmProductsListItemBottomTop {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.FarmProductsListItemBottomTopFirst {
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.FarmProductsListItemTitle {
  font-size: 110%;
  text-align: left;
  margin: 0;
  font-weight: 500;
  font-family: "Poppins";
  width: 95%;
}
.FarmProductsListItemLatin {
  font-size: 80%;
  text-align: left;
  margin: 0;
  font-weight: 500;
  font-family: "Poppins";
  width: 95%;
  font-style: italic;
}
.FarmProductsListItemDescription {
  font-size: 90%;
  text-align: left;
  margin: 0;
  font-weight: 400;
  font-family: "Poppins";
  width: 100%;
}
.FarmProductsListItemBottomTopLast {
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

.FarmProductsListItemBottomRating {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 5px;
  margin-top: 20px;
}

.FarmProductsListItemBottomRatingItem {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.FarmProductsListItemBottomBottom {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.FarmProductsListTopTitle {
  font-size: 130%;
  text-align: center;
  margin: 0;
  font-weight: 700;
  font-family: "Source Code Pro", monospace;
  width: 80%;
  margin-top: 140px;
  margin-bottom: 5px;
  color: white;
}

.FarmProductsListTopText {
  font-size: 100%;
  text-align: center;
  margin: 0;
  font-weight: 500;
  font-family: "Poppins";
  width: 90%;
  margin-top: 10px;
  margin-bottom: 80px;
  color: white;
}

.FarmProductsListBottomText {
  font-size: 100%;
  text-align: center;
  margin: 0;
  font-weight: 700;
  font-family: "Poppins";
  width: 90%;
  margin-bottom: 30px;
  color: #44a99a;
}

.FarmProductsListBottomTags {
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-bottom: 100px;
}

.FarmProductsListBottomTag {
  font-size: 90%;
  padding: 8px;
  background-color: #44a99a;
  color: white;
  margin: 5px;
}

@media only screen and (max-width: 1000px) {
  .FarmProductsListItem {
    width: 40%;
  }
}
@media only screen and (max-width: 600px) {
  .FarmProductsListItem {
    width: 90%;
  }
}

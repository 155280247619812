.LabWho {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  background-color: #44a99a;
  z-index: 10;
  padding-bottom: 30px;
}

.WhoInner {
  width: 100%;
  max-width: 900px;
  height: 100%;
  position: relative;
}

.WhoHeader {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 900px;
}

.WhoTitle {
  padding: 0;
  margin: 5px;
  font-size: 210%;
  font-weight: 700;
  color: #ffffff;
  width: 90%;
  font-family: "Source Code Pro", monospace;
}

.WhoSubtitle {
  padding: 0;
  margin: 5px;
  font-size: 140%;
  font-weight: 600;
  color: #323741;
  width: 90%;
  font-family: "Poppins";
}

.WhoBottomDiv {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 900px;
  position: relative;
}

.WhoPlxText {
  position: absolute;
  width: 96%;
  max-width: 900px;
}

.WhoPlxTextHidden {
  position: absolute;
  opacity: 0;
  width: 96%;
  max-width: 900px;
}
.WhoText {
  margin: 5px;
  font-weight: 600;
  color: #fff;
  font-size: 140%;
  font-family: "Poppins";
  width: calc(100%-10px);
}

@media only screen and (max-width: 700px) {
  .WhoTitle {
    font-size: 190%;
  }

  .WhoSubtitle {
    font-size: 130%;
  }
}

@media only screen and (max-width: 500px) {
  .WhoTitle {
    font-size: 170%;
  }

  .WhoSubtitle {
    font-size: 120%;
  }
  .WhoText {
    font-size: 120%;
  }
}

@media only screen and (max-width: 400px) {
  .WhoTitle {
    font-size: 160%;
  }

  .WhoSubtitle {
    font-size: 110%;
  }
}

.LabIntro {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 100vw;
  background-color: #323741;
  z-index: 0;

  overflow-x: hidden;
}
.IntroInner {
  width: 100%;
  max-width: 900px;
  height: 100%;
  position: relative;
}

.IntroPlx {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 900px;
  opacity: 0;
  position: relative;
  text-align: center;
}

.IntroTextDiv {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 900px;
  background-color: #323741;
  padding: 20px;
  min-height: 260px;
}

.IntroTitle {
  font-weight: 700;
  font-size: 165%;
  color: white;
  max-width: 900px;
  width: 100%;
  text-align: left;
  position: absolute;
  margin-right: 10px;
  margin-left: 10px;
  font-family: "Source Code Pro", monospace;
}

.IntroText {
  font-weight: 500;
  font-size: 60%;
  padding: 0;
  color: #e0e0e0;
  text-align: left;
  max-width: 900px;
  width: 100%;
  font-family: "Poppins";
}

.IntroGrowDiv {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 900px;
  position: relative;
  text-align: center;
}

.IntroGrowTextDiv {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 900px;
  background-color: #323741;
  padding: 20px;
}

.IndustrialLogo {
  width: 100%;
  object-fit: cover;
}
.EarthLogo {
  align-self: center;
  width: 100%;
  max-width: 900px;
  position: fixed;
  top: 0;
  z-index: -1000;
  opacity: 0.7;
}

@media only screen and (max-width: 950px) {
  .IntroTitle {
    font-weight: 700;
    font-size: 150%;
    width: 90%;
  }

  .IntroText {
    font-weight: 500;
    font-size: 60%;
    width: 90%;
  }
}

@media only screen and (max-width: 500px) {
  .IntroTitle {
    font-size: 130%;
    width: 92%;
  }

  .IntroText {
    width: 92%;
  }
}

@media only screen and (max-width: 400px) {
  .IntroTitle {
    font-size: 110%;
    width: 94%;
  }

  .IntroText {
    width: 94%;
    font-size: 70%;
  }
}

@media only screen and (max-width: 330px) {
  .IntroTitle {
    font-size: 100%;
    width: 94%;
  }

  .IntroText {
    width: 94%;
    font-size: 70%;
  }
}

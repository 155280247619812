.FarmInfo {
  width: 100vw;
  z-index: 0;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: white;
  min-height: 100vh;
}

.FarmInfoOuter {
  width: 100vw;
  z-index: 0;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: white;
}

.FarmInfoInnerDiv {
  background-color: white;
  width: 100vw;
  z-index: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}

.FarmInfoMain {
  width: calc(100vw - 300px);
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: white;
  min-height: 100vh;
  position: unset;
}

.FarmInfoMainDivider {
  width: calc(100vw - 300px);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: -5vw;
  overflow: visible;
  margin-top: 40px;
  margin-bottom: -30px;
  height: 20px;
  background-color: #44a99a;
}
.FarmInfoMainDividerBottom {
  width: calc(100vw - 300px);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: -5vw;
  overflow: visible;
  margin-top: -30px;
  margin-bottom: 30px;
  height: 20px;
  background-color: #44a99a;
}

.FarmInfosMainImg {
  width: 25%;
  max-height: 280px;
}
.FarmInfosMainImgSmall {
  width: 30%;
  max-height: 350px;
}
.FarmInfosMainImg2 {
  width: 40%;
    max-height: 350px;
}

.FarmInfoside {
  width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  position: sticky;
  top: 0;
  right: 0;
  padding-top: 40px;
}

.FarmInfosideDivOuter {
  width: 100%;
  height: 19.3vh;
  position: relative;
}
.FarmInfosideDiv {
  width: 100%;
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #44a99a;
  position: absolute;
  top: 0;
  left: 0;
}

.FarmInfosideBack {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.FarmInfosideDivTitle {
  font-size: 1.6vh;
  text-align: center;
  margin: 0;
  margin-bottom: 5px;
  font-weight: 500;
  font-family: "Changa One", cursive;
  width: 80%;
  color: #ffffff;
}
.FarmInfosideDivText {
  font-size: 1.4vh;
  text-align: center;
  margin: 0;
  font-weight: 500;
  font-family: "Poppins";
  width: 80%;
  color: #e9e9e9;
}

.FarmInfosideImg {
  width: 100%;
  height: 180px;
  z-index: 3;
  margin-top: 0;
}

.FarmInfosideDivide {
  width: 100%;
  height: 20px;
}

.FarmInfoHeader {
  width: 100%;
  background-color: #44a99a;
  padding-top: 0px;
  padding-bottom: 0px;
  z-index: 5;
  position: absolute;
  top: 0;
  left: 0;
  margin-bottom: 0;
}

.FarmInfoHeaderText {
  color: white;
  font-size: 260%;
  text-align: right;
  margin: 0;
  margin-right: 10px;
  font-weight: 900;
  font-family: "Press Start 2P", cursive;
  z-index: 5;
  margin-bottom: -1px;
}

.FarmInfoHeaderTextDiv {
  color: #373737;
  font-size: 105%;
  text-align: left;
  margin: 0;
  margin-top: 180px;
  margin-bottom: 120px;
  margin-left: 5vw;
  font-weight: 500;
  font-family: "Poppins";
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.FarmInfoMainCursive {
  color: #44a99a;
  font-size: 160%;
  text-align: center;
  margin: 0;
  margin-top: 30px;
  margin-bottom: 30px;
  margin-left: 10%;
  font-weight: 500;
  font-family: "Changa One", cursive;
  width: 80%;
}
.FarmInfoMainImages {
  width: calc(100vw - 300px);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-left: -5vw;
  overflow: visible;
  margin-top: 30px;
  margin-bottom: 30px;
}

@media only screen and (max-height: 700px) {
  .FarmInfosideDivTitle {
    font-size: 2.2vh;
  }

  .FarmInfosideDivText {
    font-size: 1.9vh;
  }
}
@media only screen and (max-width: 800px) {
  .FarmInfosMainImg {
    width: 50%;
    max-height: 300px;
    margin-top: -1px;
    margin-bottom: -1px;
  }
}
@media only screen and (max-width: 700px) {
  .FarmInfoInnerDiv {
    flex-direction: column;
    align-items: center;
  }
  .FarmInfoMain {
    width: 100vw;
    justify-content: center;
  }

  .FarmInfoMainDivider {
    width: 100vw;
    margin-left: -4vw;
  }
  .FarmInfoMainDividerBottom {
    width: 100vw;
    margin-left: -4vw;
  }

  .FarmInfoside {
    width: 100vw;

    align-items: center;
  }
  .FarmInfoHeaderTextDiv {
    color: #373737;
    font-size: 100%;
    text-align: left;
    margin: 0;
    margin-top: 160px;
    margin-bottom: 80px;
    margin-left: 0;
    font-weight: 500;
    font-family: "Poppins";
    width: calc(100% - 8vw);
  }
  .FarmInfoMainImages {
    width: 100vw;
    margin-left: -4vw;
  }
  .FarmInfosideDivOuter {
    width: 100%;
    aspect-ratio: 3/2;
    height: auto;
    position: relative;
  }
}
